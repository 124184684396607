body {
  color: #45505b !important;
}

.App {
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;

}

.logo {
  position: fixed;
  top: 20px;
  right: 20px;
  margin: 0;
  height: 40px;
}

.language-selector {
  position: fixed;
  top: 20px;
  left: 20px;
  margin: 0;
  height: 40px;
}

.language-selector p {
  display: none;
  color: #2373b9;
  margin-top: 0;
  text-align: center;
}

.language-selector button {
  height: 100%;
}

.language-selector img {
  height: 40px;
  margin: 0;
}

.select-lang-page {
  display: flex;
  width: 50vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.big-title {
  text-align: center;
  font-size: 2rem;
  padding: 0 15px;
}

.select-lang-page .language-selector {
  position: relative;
  top: 0;
  left: 0;
}

.select-lang-page .language-selector p {
  display: block;
}

.backgroundVideo {
  position: relative;
  width: 65%;
  margin: auto;
  height: 0;
  padding-top: 36.5625%;
}

.backgroundVideo > div:not(.content) {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.backgroundVideo h1 {
  position: absolute;
  top: -80px;
  width: 100%;
  text-align: center;
}

.backgroundVideo video {
  width: 65vw;
  position: absolute;
  top: 0;
}

.content {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.toggleOnHover {
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
}

.backgroundVideo:hover .content,
.backgroundVideo:hover .toggleOnHover {
  height: 68px;
}

.playPauseBtn {
  width: 145px;
  font-size: 18px;
  /*padding: 10px;*/
  border: none;
  background: #024b98;
  color: #024b98;
  cursor: pointer;
  opacity: 1;
  border-radius: 10px;
}
.playPauseBtn:hover {
  opacity: 1;
  color: white !important;
}

#full:hover {
  opacity: 1;
  color: white !important;
}
#exit:hover {
  opacity: 1;
  color: white !important;
}

.full-web-page {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 50px;
}

.full-screen-video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img {
  float: left;
  margin-right: 25px;
}

.container {
  margin-top: 20px;
}

.descArea {
  background-color: #fff;
  box-shadow: 0 0 3px #c8c8c8;
  padding: 15px;
  box-sizing: border-box;
}

.videoArea {
  position: relative;
}

.lightbox {
  position: absolute;
  display: flex;
  justify-content: center;
  top: calc(50vh - 23.34375vw);
  flex-direction: column;
  width: 75vw;
  left: 12.5vw;
  right: 0;
  height: 46.6875vw;
  z-index: 999;
}

.longbtns {
  display: block;
  color: white !important;
  font-size: 1em;
  height: auto;
  background-color: rgb(2 75 152 / 70%);
  line-height: initial;
  padding: 30px;
  border-radius: 20px;
  align-self: center;
  margin: 10px 0;
  cursor: pointer;
  transition: all 0.5s;
}
.longbtns:hover {
  color: white !important;
  background-color: #024b98 !important;
}

@media only screen and (max-device-width: 667px) and (orientation: landscape) {
  .lightbox {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;
  }

  .button {
    font-size: 16px !important;
  }

  .logo {
    position: fixed;
    top: 20px;
    right: 20px;
    margin: 0;
    height: 20px;
  }

  .language-selector {
    right: 20px;
    left: auto;
    top: 120px;
    display: flex;
    flex-direction: column;
  }

  .language-selector p {
    display: block;
  }

  .backgroundVideo {
    width: 65%;
    padding-top: 36.5625%;
    margin-left: 32px;
  }

  .backgroundVideo h1 {
    position: absolute;
    top: -72px;
    line-height: 36px;
    width: 100%;
    text-align: left;
  }

  .backgroundVideo video {
    width: 65vw;
  }

  .toggleOnHover {
    height: auto;
  }

  .backgroundVideo:hover .content,
  .backgroundVideo:hover .toggleOnHover {
    height: auto;
  }

  .content {
    position: relative;
  }

  .content .MuiBox-root {
    position: relative;
    padding: 16px 0;
  }
}

@media only screen and (max-width: 425px) and (orientation: portrait) {
  .lightbox {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    padding: 32px;
  }

  .button {
    font-size: 16px !important;
  }

  .backgroundVideo {
    width: 90%;
    padding-top: 50.625%;
  }

  .backgroundVideo h1 {
    position: absolute;
    top: -72px;
    line-height: 36px;
    width: 100%;
    text-align: left;
  }

  .backgroundVideo video {
    width: 90vw;
  }

  .toggleOnHover {
    height: auto;
  }

  .backgroundVideo:hover .content,
  .backgroundVideo:hover .toggleOnHover {
    height: auto;
  }

  .content {
    position: relative;
  }

  .content .MuiBox-root {
    position: relative;
    padding: 16px 0;
  }
}

@media only screen and (max-width: 1024px) {
  .longbtns {
    display: block;
    color: white !important;
    font-size: 0.5em;
    /*width: fit-content;*/
    height: auto;

    background-color: rgb(2 75 152 / 70%);
    line-height: initial;
    padding: 10px;
    border-radius: 20px;
  }
  .ktext {
    font-size: 0.5em !important;
  }
}

.featherlight .featherlight-content {
  /*height: 200px;*/
  width: auto;

  border-radius: 20px;
  background-color: rgb(1 42 86 / 90%);
}

.featherlight .featherlight-close-icon {
  display: none;
}

.button {
  color: #024b98;
  color: white;
  width: auto;
  font-size: 20px;
  display: initial;
  text-align: center;
}

#playPauseBtn {
  color: white;
}

.section-title {
  cursor: pointer;
  height: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  background-color: #024b98;
  color: #fff;
  white-space: nowrap;
  border-right: 2px solid #fff;
}

.section {
  position: relative;
  opacity: 0.95;
}

.section.active {
  opacity: 1;
  font-weight: bold;
}

.section.disabled {
  opacity: 0.5;
}

.section-title:hover {
  box-shadow: 0 6px 20px 0px #000;
  min-width: fit-content;
  width: 100%;
  position: absolute;
  z-index: 9999;
  border: none;
}

.section:last-child .section-title {
  border-right: none !important;
}
